import React from "react";
import "./MainContent.scss";
import Channels from "./channel/Channels";
import SideBar from "./SideBar";
import InfoBar from "./InfoBar";
import http from "../services/HttpService";
import { toQueryString } from "../utils/UrlUtil";
import {
  getAdsLabel,
  getBlogSettings,
  getHeaderDesktopAds,
  getTopDesktopAds,
  isChannelSearchPage,
  isStartPage,
} from "../utils/Util";
import SignupWelcomeMessage from "./userPages/SignupWelcomeMessage";
import BlogRecommendations from "./blog/BlogRecommendations";
import { isTableDevice } from "../utils/DeviceUtil";
import Providers from "./stream/Providers";
import logo2 from "../images/svensklicens1.png";
import logo3 from "../images/cug-logo.png";
import logo4 from "../images/Skarmavbild.png";
import logo5 from "../images/natcasinoutanlicens-logo.png";
import logo6 from "../images/kasinoutansvensklicens.png";
import logo7 from "../images/utlandska-casino.webp";
import logo8 from "../images/casinos-without-swedish-license.png";
import logo9 from "../images/paynplayutanlicens.png";

class MainContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      blogSettings: {},
    };
  }

  componentDidMount() {
    const {
      match: { params },
    } = this.props;
    let type = params.type;
    this.setState({ blogSettings: getBlogSettings() });
    if (type && !isChannelSearchPage()) {
      http
        .get(
          "/api/categories/findAllByGenre" +
            toQueryString({ type: params.type })
        )
        .then((response) => {
          let { categories } = response.data.content;
          this.setState({ categories: categories });
        })
        .catch((error) => {
          console.log(error.toJSON);
        });
    }
  }

  render() {
    const {
      match: { params },
    } = this.props;
    let { categories, blogSettings } = this.state;
    const topOff = this.state.isTop;
    return (
      <div className="main-content container">
        {!isChannelSearchPage() ? <InfoBar categories={categories} /> : ""}
        <SignupWelcomeMessage />
        {blogSettings.showOnStartPage || true ? (
          <BlogRecommendations
            heading={"ARTIKLAR OCH TIPS"}
            featured={true}
            order={"asc"}
            sort={"tabIndex"}
          />
        ) : null}

        <Providers hideText={true} />
        <div className="row">
          {getTopDesktopAds()}
          {getHeaderDesktopAds()}
          {isTableDevice() ? (
            <>
              {getAdsLabel()}
              <div id="cncpt-tab_lb1"></div>{" "}
            </>
          ) : (
            ""
          )}
        </div>

        <div className="row timeline-layout">
          <div
            className={`col-12 col-sm-12 col-md-10 ${
              isChannelSearchPage()
                ? "col-lg-12 noPadding"
                : "col-lg-9 paddingLeft0"
            }`}
          >
            <Channels
              type={params.type}
              channelFriendlyUrl={params.channelFriendlyUrl}
            />

            {isStartPage() ? (
              <>
                <section className="sponsorBanner">
                  {/* <span className="title">Samarbeten</span> */}
                  <ul>
                    <li>
                      <a
                        href=" https://casinoutangränser.se/casino-utan-licens/"
                        target="_blank"
                      >
                        <img src={logo3} alt="Casinoutangränser.se" />
                      </a>
                    </li>

                    <li>
                      <a
                        href="https://casinoutansvensklicens.co/"
                        target="_blank"
                      >
                        <img
                          src={logo2}
                          alt="Bäst utländska casinon utan licens"
                        />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://xn--ntcasinoutanlicens-ltb.com/"
                        target="_blank"
                      >
                        <img
                          src={logo5}
                          alt="Casino utan licens - Nätcasinoutanlicens.com"
                        />
                      </a>
                    </li>
                    {/*<li>*/}
                    {/*  <a href="https://passagen.se" target="_blank">*/}
                    {/*    <img*/}
                    {/*      src={logo4}*/}
                    {/*      alt="Alla Casino utan Svensk Licens & Spelpaus"*/}
                    {/*      style={{ height: "100px" }}*/}
                    {/*    />*/}
                    {/*  </a>*/}
                    {/*</li>*/}
                  </ul>
                </section>

                <section className="sponsorBanner">
                  {/* <span className="title">Samarbeten</span> */}
                  <ul>
                    <li>
                      <a
                        href="https://kasinoutansvensklicens.com/"
                        target="_blank"
                      >
                        <img src={logo6} alt="casino utan Spelpaus" />
                      </a>
                    </li>

                    <li>
                      <a
                        href="https://xn--utlndska-2za.casino/"
                        target="_blank"
                      >
                        <img src={logo7} alt="bästa utländska casino" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.casinoswithoutlicense.com/"
                        target="_blank"
                      >
                        <img src={logo8} alt="casino without Swedish license" />
                      </a>
                    </li>
                    <li>
                      <a href="https://paynplayutanlicens.se/" target="_blank">
                        <img src={logo9} alt="Pay n play casinon" />
                      </a>
                    </li>
                  </ul>
                </section>
              </>
            ) : null}
          </div>
          <div className="col-12 col-sm-12 col-md-3 col-lg-3 padding0">
            <SideBar />
          </div>
        </div>
      </div>
    );
  }
}

export default MainContent;
